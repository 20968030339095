<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        定点服务机构
      </template>
      <template #input>
        <a-input placeholder="请输入关键字" />
        <a-button class="all_boder_btn btn" type="primary">搜索</a-button>
        <a-button class="all_boder_btn btn" @click="state=1;visible=true">新建</a-button>
        <a-button class="all_boder_btn btn">下载模板</a-button>
        <a-button class="all_boder_btn">批量导入</a-button>
      </template>
    </HeaderBox>


    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="item=>item.id"
        :columns="columns"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total:total,
          current:pageNumber,  
          defaultPageSize:pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>

        <template slot="operation">
          <div class="btn_router_link">
            <a @click="state=2;visible=true">编辑</a>
            <span>|</span>
            <a>删除</a>
          </div>
        </template>
      </a-table>
      
    </div>
    <!-- 新建/编辑 -->
    <a-modal v-model="visible" okText='确认' :title="(state==1?'新建':'编辑')+'机构'">
      <a-row class="rows">
        <a-col span="5" class="left">机构名称</a-col>
        <a-col span="19" class="right">
          <a-input placeholder="请输入机构名称" />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">机构地区</a-col>
        <a-col span="19" class="right">
          <a-row :gutter="[8,0]">
            <a-col span="8">
              <a-select style="width:100%;" placeholder="请选择省">
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option value="1">区1</a-select-option>
                <a-select-option value="2">区2</a-select-option>
                <a-select-option value="3">区3</a-select-option>
              </a-select>
            </a-col>
            <a-col span="8">
              <a-select style="width:100%;" placeholder="请选择市">
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option value="1">区1</a-select-option>
                <a-select-option value="2">区2</a-select-option>
                <a-select-option value="3">区3</a-select-option>
              </a-select>
            </a-col>
            <a-col span="8">
              <a-select style="width:100%;" placeholder="请选择区">
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option value="1">区1</a-select-option>
                <a-select-option value="2">区2</a-select-option>
                <a-select-option value="3">区3</a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="19" offset="5" class="right">
          <a-textarea
            placeholder="请输入详细地址"
            :auto-size="{ minRows: 2, maxRows: 6 }"
          />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">联系电话</a-col>
        <a-col span="19" class="right">
          <a-input placeholder="请输入联系电话" />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">服务项目</a-col>
        <a-col span="19" class="right">
          <a-input placeholder="请输入服务项目"/>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">经办人</a-col>
        <a-col span="19" class="right">
          <a-input placeholder="请输入经办人"/>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from '@/components/HeaderBox.vue'
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "机构名称",
    align:'center',
    dataIndex: "code4"
  },
  {
    title: "详细地址",
    align:'center',
    dataIndex: "code5"
  },
  {
    title: "联系电话",
    align:'center',
    dataIndex: "code6"
  },
  {
    title: "服务项目",
    align:'center',
    dataIndex: "code7"
  },
  {
    title: "服务项目",
    align:'center',
    dataIndex: "code8"
  },
  {
    title: "操作",
    align:'center',
    width: "160px",
    scopedSlots: { customRender: "operation" }
  }
];
const columns2 = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "省",
    align:'center',
    dataIndex: "code1"
  },
  {
    title: "市",
    align:'center',
    dataIndex: "code2"
  },
  {
    title: "区",
    align:'center',
    dataIndex: "code3"
  },
  {
    title: "机构名称",
    align:'center',
    dataIndex: "code4"
  },
  {
    title: "详细地址",
    align:'center',
    dataIndex: "code5"
  },
  {
    title: "联系电话",
    align:'center',
    dataIndex: "code6"
  },
  {
    title: "服务项目",
    align:'center',
    dataIndex: "code7"
  },
  {
    title: "操作",
    align:'center',
    width: "160px",
    scopedSlots: { customRender: "operation" }
  }
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      state: 1, // 1新建，2编辑
      visible: false,
      columns, // 表头
      columns2, // 表头
      tableData:[], // 列表数据
      loading:false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      tabIndex: '1',
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.pageNumber = e.current
      this.pageSize = e.pageSize
    },
    // 跳详情页
    goDetails(){
      this.$router.push({ path: "/admin/UserManage/userDetail", query: { id: 1 } })
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    // 生成假数据
    for(let i=1; i<40; i++){
      this.tableData.push({
        id:i,code1:'数据' + i, code2:'数据' + i, code3:'数据' + i, code4:'数据' + i, code5:'数据' + i, code6:'数据' + i, code7:'数据' + i, code8:'数据' + i,
      })
    }
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.table-box{
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}
.table {
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  /deep/.ant-table {
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect{
    padding-left: 6px;
  }
}
.rows{
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0;
  }
  .left{
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::before{
      content: '*';
      color: red;
      margin-right: 2px;
    }
    &::after{
      content: '：';
    }
  }
  .flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    input{
      flex: 1;
    }
    a{
      margin-left: 8px;
    }
  }
}
.radio{
  display: flex;
  flex-direction: row;
  width: 100%;;
  overflow-x: auto;
  overflow-y: hidden;
  .radioItem{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    img{
      max-width: 100px;
      max-height: 120px;
      margin-bottom: 10px;
    }
  }
}
</style>
